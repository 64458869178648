import { db } from '@/plugins/firebase'
import type { Campaign } from '@/resources/campaign'
import { Coupon } from '@/resources/coupon'
import {
  collection,
  doc,
  type DocumentData,
  type DocumentSnapshot,
  getDoc,
  getDocs,
  query,
  type QuerySnapshot,
  where,
} from 'firebase/firestore'

const loadedCampaigns: {
  [key: string]: QuerySnapshot<DocumentData> | DocumentSnapshot<DocumentData>
} = {}
const loadedCoupons: { [key: string]: Coupon[] } = {}

/**
 * Fetches a campaign by its unique code from the Firestore database.
 * @param db - The Firestore database instance.
 * @param campaignCode - The unique code of the campaign to be fetched.
 * @returns The data of the campaign if found, otherwise returns null.
 */
export async function getCampaignByCode(campaignCode: string) {
  let querySnapshot = loadedCampaigns[
    campaignCode
  ] as QuerySnapshot<DocumentData>

  if (!querySnapshot) {
    const campaignsRef = collection(db, 'campaigns')
    const q = query(campaignsRef, where('campaignCode', '==', campaignCode))

    querySnapshot = await getDocs(q)

    loadedCampaigns[campaignCode] = querySnapshot
  }

  if (querySnapshot.empty) {
    return null
  } else {
    const doc = querySnapshot.docs[0]
    const data = doc.data() as Campaign

    return {
      ...data,
      id: doc.id,
    }
  }
}

export async function getCampaignById(campaignId: string) {
  let snapshot = loadedCampaigns[campaignId] as DocumentSnapshot<DocumentData>

  if (!snapshot) {
    const campaignRef = doc(db, 'campaigns', campaignId)
    snapshot = await getDoc(campaignRef)
  }

  if (!snapshot.exists()) {
    return null
  } else {
    loadedCampaigns[campaignId] = snapshot

    const data = snapshot.data() as Campaign

    return {
      ...data,
      id: snapshot.id,
    }
  }
}

export async function getCampaignCoupons(campaignId: string) {
  if (loadedCoupons[campaignId]) {
    return loadedCoupons[campaignId]
  }

  const couponsSnapshot = await getDocs(
    collection(db, 'campaigns', campaignId, 'coupons')
  )

  if (couponsSnapshot.empty) {
    return []
  }

  loadedCoupons[campaignId] = couponsSnapshot.docs.map(
    (doc) => doc.data() as Coupon
  )

  return loadedCoupons[campaignId]
}
