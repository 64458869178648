import { render, staticRenderFns } from "./EditPackage.vue?vue&type=template&id=0c61116d&scoped=true"
import script from "./EditPackage.vue?vue&type=script&lang=ts"
export * from "./EditPackage.vue?vue&type=script&lang=ts"
import style0 from "./EditPackage.vue?vue&type=style&index=0&id=0c61116d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c61116d",
  null
  
)

export default component.exports