import { i18n } from '@/locales/i18n'

/**
 * Determines the status of a campaign based on various conditions.
 * @param {boolean} isConfirmed - Indicates if the coupon is confirmed.
 * @param {boolean} isAssessed - Indicates if the dialog has been assessed.
 * @param {boolean} isEnded - Indicates if the dialog has ended.
 * @returns {string} - The status of the campaign.
 */
export function determineCampaignStatus(
  isConfirmedOrStatus: boolean | string,
  isAssessed?: boolean,
  isEnded?: boolean
) {
  if (typeof isConfirmedOrStatus === 'string') {
    // status string
    switch (isConfirmedOrStatus) {
      case '登録済み':
      case 'Registered':
        return i18n.t('campaignResult.status.registered') as string

      case '分析完了':
      case 'Analyzed':
        return i18n.t('campaignResult.status.analyzed') as string

      case '対話完了':
      case 'Completed':
        return i18n.t('campaignResult.status.completed') as string

      case '未登録':
      case 'Unregistered':
        return i18n.t('campaignResult.status.unregistered') as string

      default:
        return ''
    }
  }

  const isConfirmed = isConfirmedOrStatus
  switch (true) {
    case !isConfirmed:
      return i18n.t('campaignResult.status.unregistered') as string

    case isConfirmed && isEnded && isAssessed:
      return i18n.t('campaignResult.status.analyzed') as string

    case isConfirmed && isEnded:
      return i18n.t('campaignResult.status.completed') as string

    case isConfirmed:
      return i18n.t('campaignResult.status.registered') as string

    default:
      return ''
  }
}
