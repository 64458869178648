<template>
  <div class="d-flex flex-column" style="gap: 20px">
    <span class="text-h6">{{ $t('packageResults.overviewByCat') }}</span>

    <v-tabs v-model="tabIndex" color="#1b2643">
      <v-tab tab-value="cefrOverall">{{ $t('packageResults.overall') }}</v-tab>
      <v-tab tab-value="range">{{ $t('packageResults.range') }}</v-tab>
      <v-tab tab-value="accuracy">{{ $t('packageResults.accuracy') }}</v-tab>
      <v-tab tab-value="phonology">{{ $t('packageResults.phonology') }}</v-tab>
      <v-tab tab-value="fluency">{{ $t('packageResults.fluency') }}</v-tab>
      <v-tab tab-value="coherence">{{ $t('packageResults.coherence') }}</v-tab>
      <v-tab tab-value="interaction">
        {{ $t('packageResults.interaction') }}
      </v-tab>
    </v-tabs>

    <template v-if="loading">
      <v-card :loading="loading">
        <div style="padding: 16px">
          {{ $t('packageResults.loading') }}
        </div>
      </v-card>
    </template>
    <v-simple-table v-else dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="sticky-header" style="width: 120px" />
            <template v-for="item in data">
              <th
                v-if="speakingTestOnly ? item.scenarioShouldAssess : true"
                :key="item.campaignId"
                style="width: 300px; min-width: 300px"
              >
                {{ item.campaign?.name }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="level in cefrLevels" :key="level">
            <th class="sticky-header" style="width: 300px; min-width: 300px">
              <strong>{{ level }}</strong>
            </th>
            <template v-for="(value, i) in tableData(level)">
              <td v-if="value !== 'HIDE'" :key="i">
                {{ value }}
              </td>
            </template>
          </tr>
          <tr>
            <th class="sticky-header">
              <strong>{{ $t('packageResults.avgScore') }}</strong>
            </th>
            <template v-for="(value, i) in averageTableData()">
              <td v-if="value !== 'HIDE'" :key="i">
                {{ value }}
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <template v-if="loading">
      <v-card :loading="loading">
        <div style="padding: 16px">
          {{ $t('packageResults.loading') }}
        </div>
      </v-card>
    </template>
    <v-data-table
      v-else
      ref="dataTable"
      :headers="headers"
      :items="tableItems"
      :items-per-page="itemsPerPage"
      :page="currentPage"
      locale="ja"
      class="elevation-0 freeze-columns"
      dense
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="sticky-header" style="width: 150px">
            {{ item.displayName }}
          </td>
          <td>{{ item.couponCode }}</td>
          <template v-for="(r, i) in item.campaignCells">
            <overview-coupon-column
              :key="i"
              :item="r"
              :tab-index="tabIndex"
              :coupon-code="r.couponCode"
            />
          </template>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import type { CampaignResults } from '@/resources/package'
import type { Result } from '@/resources/result'
import { defineComponent } from '@vue/composition-api'
import OverviewCouponColumn from './OverviewCouponColumn.vue'
import Vue from 'vue'

type Header = {
  text: string
  value: string
  width: number
  class?: string
}
type CampaignRow = {
  displayName: string
  couponCode: string
  campaignCells: CampaignResults['rows'][0][]
}

export default defineComponent({
  name: 'OverviewByCategory',

  components: {
    OverviewCouponColumn,
  },

  props: {
    data: {
      type: Array as () => CampaignResults[],
      required: true,
    },
    speakingTestOnly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tabIndex: 'cefrOverall',
      cefrLevels: ['Pre-A1', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      currentPage: 1,
      itemsPerPage: 100,
    }
  },

  computed: {
    headers(): Header[] {
      const baseHeaders = [
        {
          text: this.$t('packageResults.userDisplayName') as string,
          value: 'displayName',
          width: 150,
          class: 'sticky-header',
        },
        {
          text: this.$t('packageResults.coupon') as string,
          value: 'couponCode',
          width: 150,
        },
      ]

      const campaignHeaders = this.data
        .filter((item) => !this.speakingTestOnly || item.scenarioShouldAssess)
        .map((item) => ({
          text: item.campaign?.name || '',
          value: `campaign_${item.campaignId}`,
          width: 300,
        }))

      return [...baseHeaders, ...campaignHeaders]
    },
    tableItems(): CampaignRow[] {
      const groupedCoupons = this.data
        .flatMap((d) => d.rows)
        .reduce(
          (acc, row) => {
            const scenarioShouldAssess = this.data.find(
              (d) => d.campaignId === row.campaignId
            )?.scenarioShouldAssess

            const shouldShow = this.speakingTestOnly
              ? scenarioShouldAssess
              : true
            if (!shouldShow) return acc

            if (!acc[row.couponCode]) {
              acc[row.couponCode] = {
                displayName: row.displayName || '-',
                couponCode: row.couponCode,
                campaignCells: [],
              }
            }

            acc[row.couponCode].campaignCells.push(row)
            return acc
          },
          {} as Record<
            string,
            {
              displayName: string
              couponCode: string
              campaignCells: CampaignResults['rows'][0][]
            }
          >
        )

      return Object.values(groupedCoupons)
    },
  },

  methods: {
    getCampaignRows(campaignId: string) {
      return this.data.find((d) => d.campaignId === campaignId)?.rows
    },
    tableData(level: string) {
      let results = []

      for (const d of this.data) {
        let count = 0
        const shouldShow = this.speakingTestOnly ? d.scenarioShouldAssess : true
        if (!shouldShow) {
          results.push('HIDE')
          continue
        }

        for (const r of d.rows) {
          const score =
            r.score?.[this.tabIndex as keyof Result['fastResult']['score']]

          if (score?.cefrLevel === level) {
            count += 1
          }
        }

        results.push(count)
      }

      return results
    },
    averageTableData() {
      let averages = []

      for (const d of this.data) {
        let totalScore = 0

        const shouldShow = this.speakingTestOnly ? d.scenarioShouldAssess : true
        if (!shouldShow) {
          averages.push('HIDE')
          continue
        }

        for (const r of d.rows) {
          const score =
            r.score?.[this.tabIndex as keyof Result['fastResult']['score']]
          if (score) {
            totalScore += score.score
          }
        }
        const avg = totalScore / d.rows.length
        averages.push(window.isNaN(avg) ? '0' : avg.toFixed(2))
      }

      return averages
    },
    /**
     * Adds a scroll event listener to handle fixed columns.
     */
    addScrollListener() {
      const dataTableRef = this.$refs.dataTable as Vue

      if (dataTableRef && dataTableRef.$el) {
        const tableWrapper = dataTableRef.$el.querySelector(
          '.v-data-table__wrapper'
        ) as HTMLElement

        if (tableWrapper) {
          tableWrapper.addEventListener('scroll', this.handleScroll)
        }
      }
    },
    /**
     * Handles the scroll event to keep fixed columns in place.
     */
    handleScroll(event: Event) {
      const tableWrapper = event.target as HTMLElement
      const left = tableWrapper.scrollLeft
      const fixedColumns = tableWrapper.querySelectorAll('th:nth-child(-n+1)')

      fixedColumns.forEach((column) => {
        ;(column as HTMLElement).style.transform = `translateX(${left}px)`
        ;(column as HTMLElement).style.background = 'white'
      })
    },
  },
  mounted() {
    this.addScrollListener()
  },
})
</script>

<style scoped>
.sticky-header {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}

.sticky-header-no-border:nth-child(1) {
  border-right: 0;
}
</style>
