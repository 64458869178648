<style scoped>
.dialog-table td:first-child {
  width: 200px;
  font-weight: 700 !important;
}
</style>

<template #default="dialog">
  <v-card>
    <v-toolbar color="#1b2643" dark>
      {{ $t('newPackage.confirmTable.header') }}
    </v-toolbar>
    <v-card-text style="padding: 0; overflow-x: scroll; max-height: 500px">
      <v-expansion-panels accordion multiple :value="[0, 1, 2]">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="text-h6">
              {{ $t('newPackage.confirmTable.subHeader') }}
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div class="d-flex flex-column" style="gap: 20px">
              <table class="dialog-table">
                <tr>
                  <td>{{ $t('newPackage.confirmTable.packageCode') }}</td>
                  <td>{{ packageCode }}</td>
                </tr>

                <tr>
                  <td>{{ $t('newPackage.confirmTable.packageName') }}</td>
                  <td>{{ packageName }}</td>
                </tr>

                <tr>
                  <td>{{ $t('newPackage.confirmTable.viewingPeriod') }}</td>
                  <td>
                    {{ formattedDateTime(startViewingDate, startViewingTime) }}
                    ~ {{ formattedDateTime(endViewingDate, endViewingTime) }}
                  </td>
                </tr>
              </table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="text-h6">
              {{ $t('newPackage.confirmTable.campaigns.title') }}
              {{ `(${campaignsList.length})` }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column" style="gap: 20px">
              <div
                v-for="(campaign, index) in campaignsList"
                :key="index"
                class="d-flex flex-column"
              >
                <p class="text-body-1 font-weight-bold">
                  <u>
                    {{ $t('newPackage.confirmTable.campaigns.subTitle') }}
                    {{ index + 1 }}
                  </u>
                </p>
                <table class="dialog-table">
                  <tr>
                    <td>
                      {{ $t('newPackage.confirmTable.campaigns.body[0]') }}
                    </td>
                    <td>{{ campaign.name }}</td>
                  </tr>

                  <tr>
                    <td>
                      {{ $t('newPackage.confirmTable.campaigns.body[1]') }}
                    </td>
                    <td>
                      {{ getScenarioName(campaign.scenarioDocId) }}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {{ $t('newPackage.confirmTable.campaigns.body[2]') }}
                    </td>
                    <td>1</td>
                  </tr>

                  <tr>
                    <td>
                      {{ $t('newPackage.confirmTable.campaigns.body[3]') }}
                    </td>
                    <td>
                      {{
                        formattedDateTime(
                          campaign.startInterviewDate,
                          campaign.startInterviewTime
                        )
                      }}
                      ~
                      {{
                        formattedDateTime(
                          campaign.endInterviewDate,
                          campaign.endInterviewTime
                        )
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="text-h6">
              {{ $t('newPackage.confirmTable.campaigns.coupon.title') }}
              {{ `(${couponList.length})` }}
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="couponList"
              hide-default-footer
              dense
            ></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-card-actions class="justify-end pt-4 pb-4">
      <v-btn text @click="$emit('cancel')">
        <v-icon left>mdi-arrow-u-left-top</v-icon>
        {{ $t('newPackage.confirmTable.campaigns.cancel') }}
      </v-btn>
      <v-btn
        dark
        color="#1b2643"
        @click="createPackage()"
        :disable="isLoading"
        :loading="isLoading"
      >
        <v-icon left>mdi-check</v-icon>
        {{ $t('newPackage.confirmTable.campaigns.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { createIsoUtcDateTime } from '@/components/utils/dateUtils'
import { functions } from '@/plugins/firebase'
import type { NewCampaign } from '@/resources/newCampaign'
import store from '@/store'
import { defineComponent, PropType } from '@vue/composition-api'
import { httpsCallable } from 'firebase/functions'
import moment from 'moment'

type Scenario = {
  scenarioDocId: string
  scenarioName: string
}

type Coupon = {
  couponCode: string
  password: string
}

export default defineComponent({
  name: 'ConfirmCreateCampaignTable',

  props: {
    campaigns: {
      type: Array as PropType<NewCampaign[]>,
      required: true,
      default: () => [
        {
          name: '',
          scenarioDocId: '',
          startInterviewDate: null,
          startInterviewTime: '',
          endInterviewDate: null,
          endInterviewTime: '',
          errorMessage: '',
        },
      ],
    },
    scenarios: {
      type: Array as PropType<Scenario[]>,
      required: true,
      default: () => [
        {
          scenarioDocId: '',
          scenarioName: '',
        },
      ],
    },
    coupons: {
      type: Array as PropType<Coupon[]>,
      default: () => [
        {
          couponCode: '',
          password: '',
        },
      ],
    },
    packageCode: {
      type: String,
      default: '',
    },
    packageName: {
      type: String,
      default: '',
    },
    startViewingDate: {
      type: String as PropType<string | null>,
      default: '',
    },
    startViewingTime: {
      type: String,
      default: '',
    },
    endViewingDate: {
      type: String as PropType<string | null>,
      default: '',
    },
    endViewingTime: {
      type: String,
      default: '',
    },
    startInterviewDate: {
      type: String as PropType<string | null>,
      default: '',
    },
    startInterviewTime: {
      type: String,
      default: '',
    },
    endInterviewDate: {
      type: String as PropType<string | null>,
      default: '',
    },
    endInterviewTime: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      campaignsList: [] as NewCampaign[],
      scenarioList: [] as Scenario[],
      couponList: [] as Coupon[],
      customerId: store.getters.customerId as string,

      headers: [
        { text: 'Coupon Code', value: 'couponCode' },
        { text: 'Password', value: 'password' },
      ],

      isLoading: false as boolean,
    }
  },

  async created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.campaignsList = this.$props.campaigns as NewCampaign[]
      this.scenarioList = this.$props.scenarios as Scenario[]
      this.couponList = this.$props.coupons as Coupon[]
    },

    getScenarioName(scenarioId: string | undefined) {
      const scenario = this.scenarioList.find(
        (s) => s.scenarioDocId === scenarioId
      )
      return scenario ? scenario.scenarioName : ''
    },

    formattedDateTime(
      date: string | null | undefined,
      time: string | undefined
    ) {
      if (!date) return time as string
      if (!time) time = '00:00'
      const dateFormat = new Date(date)
      const year = dateFormat.getFullYear()
      const day = String(dateFormat.getDate()).padStart(2, '0')
      const month = String(dateFormat.getMonth() + 1).padStart(2, '0')

      const dateTime = year + '/' + month + '/' + day + ' ' + time

      return dateTime as string
    },

    async createPackage() {
      try {
        this.isLoading = true
        const createPackage = httpsCallable(functions, 'createPackage')

        const response = (
          await createPackage({
            code: this.packageCode,
            name: this.packageName,
            openAt: createIsoUtcDateTime(
              this.startViewingDate,
              this.startViewingTime
            ),
            closedAt: createIsoUtcDateTime(
              this.endViewingDate,
              this.endViewingTime
            ),
            customerId: this.customerId,
            campaigns: this.campaignsList,
            coupons: this.couponList,
          })
        ).data
        if (response) {
          console.log('created package successfully')
          this.$emit('resetValues')
          this.$emit('cancel')
        }
      } catch (error) {
        console.log('error:', error)
      } finally {
        this.isLoading = false
        this.$router.push('/dashboard/home')
      }
    },
  },
})
</script>
