import { db } from '@/plugins/firebase'
import type { Learner } from '@/resources/learner'
import type { Ticket } from '@/resources/ticket'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'

/**
 * Fetch learners and keep the data in memory to avoid fetching the same learner multiple times.
 */

const loadedLearners: { [key: string]: Learner } = {}
const loadedLearnerTickets: { [key: string]: Ticket } = {}

export async function getLearnerById(id: string) {
  try {
    if (!loadedLearners[id]) {
      const snapshot = await getDoc(doc(db, 'learners', id))
      loadedLearners[id] = snapshot.data() as Learner
    }

    return loadedLearners[id]
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getLearnerTicketFromCouponCode(
  learnerId: string,
  couponCode: string,
  campaignId: string
) {
  const key = `${learnerId}-${couponCode}-${campaignId}`
  if (loadedLearnerTickets[key]) {
    return loadedLearnerTickets[key]
  }

  // Get learner from ticket
  const q = query(
    collection(db, 'learners', learnerId, 'tickets'),
    where('customerOptions.couponCode', '==', couponCode)
  )
  const snapshot = await getDocs(q)

  for (const t of snapshot.docs) {
    const data = t.data() as Ticket
    if (data.campaign.id === campaignId) {
      loadedLearnerTickets[key] = t.data() as Ticket
      return loadedLearnerTickets[key]
    }
  }
}
