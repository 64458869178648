import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"ma-10 card_shadow"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"headline"},[_c(VCol,{staticClass:"active_text"},[_c('p',[_vm._v(_vm._s(_vm.$t('packageResults.title')))])])],1)],1),_c('div',{staticClass:"d-flex flex-column pa-5",staticStyle:{"gap":"40px"}},[_c('div',{staticClass:"d-flex justify-end"},[_c(VSwitch,{attrs:{"label":_vm.$t('packageResults.speakingTestOnly')},model:{value:(_vm.speakingTestOnly),callback:function ($$v) {_vm.speakingTestOnly=$$v},expression:"speakingTestOnly"}})],1),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"20px"}},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('packageResults.packageName')))]),_vm._v(" "+_vm._s(_vm.packageData?.name)+" ("+_vm._s(_vm.packageData?.code)+") ")]),_c('learner-population-data',{attrs:{"data":_vm.learnerPopulationData,"speakingTestOnly":_vm.speakingTestOnly,"loading":_vm.loading}}),_c('overview-by-category',{attrs:{"speakingTestOnly":_vm.speakingTestOnly,"data":_vm.learnerPopulationData,"loading":_vm.loading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }