import { i18n } from '@/locales/i18n'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string,
  },
})
