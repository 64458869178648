import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { getAuth } from 'firebase/auth'
import store from '@/store'

// Top layout
import TopLayout from '@/layouts/TopLayout.vue'
import SignUp from '@/views/Top/SignUpView.vue'
import SignIn from '@/views/Top/SignInView.vue'
import Verified from '@/views/Top/VerifiedView.vue'

// Dashboard layout
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import Home from '@/views/Dashboard/HomeView.vue'
import Security from '@/views/Dashboard/Security.vue'
import SecurityPass from '@/views/Dashboard/SecurityPass.vue'
import NewExam from '@/views/Dashboard/NewExamView.vue'
import Learners from '@/views/Dashboard/LearnersView.vue'
import NewLearners from '@/views/Dashboard/NewLearnersView.vue'
import Coupons from '@/views/Dashboard/CouponsView.vue'
import SignOut from '@/views/Dashboard/SignOutView.vue'
import Guide from '@/views/Dashboard/GuideView.vue'
import NewPackage from '@/views/Dashboard/NewPackage.vue'
import CampaignResults from '@/views/Dashboard/CampaignResults.vue'
import PackageResults from '@/views/Dashboard/PackageResults.vue'
import ConversationAnalysis from '@/views/Dashboard/ConversationAnalysis.vue'
import EditPackage from '@/views/Dashboard/EditPackage.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'InteLLA',
    redirect: 'signin',
    component: TopLayout,
    children: [
      {
        path: 'signin',
        name: 'Sign In',
        component: SignIn,
        props: (route) => ({
          snackbarAction: Boolean(route.params.snackbarAction),
          snackbarText: String(route.params.snackbarText),
          snackbarColor: String(route.params.snackbarColor),
        }),
      },
      {
        path: 'signup',
        name: 'Sign Up',
        component: SignUp,
      },
      {
        path: 'verified',
        name: 'Verified',
        component: Verified,
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'InteLLA Customer Console',
    component: DashboardLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'security',
        name: 'Security',
        component: Security,
      },
      {
        path: 'securitypass',
        name: 'SecurityPass',
        component: SecurityPass,
      },
      {
        path: 'exam/new',
        name: 'New Exam',
        component: NewExam,
      },
      {
        path: 'learners',
        name: 'Learners',
        component: Learners,
      },
      {
        path: 'newlearners',
        name: 'New Learners',
        component: NewLearners,
      },
      {
        path: 'coupons',
        name: 'Coupons',
        component: Coupons,
      },
      {
        path: 'signout',
        name: 'Sign Out',
        component: SignOut,
      },
      {
        path: 'guide',
        name: 'Guide',
        component: Guide,
      },
      {
        path: 'campaign-results/:campaignCode',
        name: 'Campaign Results',
        component: CampaignResults,
      },
      {
        path: 'package-results/:packageCode',
        name: 'Package Results',
        component: PackageResults,
      },
      {
        path: 'conversation-analysis/:dialogId',
        name: 'Conversation Analysis',
        component: ConversationAnalysis,
      },
      {
        path: 'package/new',
        name: 'New Package',
        component: NewPackage,
      },
      {
        path: 'package/edit/:packageId',
        name: 'Edit Package',
        component: EditPackage,
      },
    ],
  },
  {
    path: '/*',
    redirect: 'signin',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 認証状態の確認、認証必須画面でリロード時にauthにサインイン状態であれば元の画面に復帰
    getAuth().onAuthStateChanged((user) => {
      // 多重サインインおよび不正画面遷移の防止：サインイン後URLを他タブにコピー等の操作で画面表示に用いるemailがセッションストレージになければサインイン画面へ
      // （サインイン画面アクセス時に古いタブもサインアウト状態となる）
      if (!store.state.isCreatingUser) {
        if (user && store.state.email) {
          next()
        } else {
          next({ name: 'Sign In', query: { redirect: to.path } })
        }
      }
    })
  } else {
    next()
  }
})

export default router
