import { roundToTwoDecimals } from '@/components/utils/commonUtils'
import type { Coupon } from '@/resources/coupon'
import { determineCampaignStatus } from './determineCampaignStatus'
import { calculateDuration, getJstTime } from '@/components/utils/dateUtils'
import { i18n } from '@/locales/i18n'
import { getLearnerById, getLearnerTicketFromCouponCode } from './learnersUtils'
import { getResultsByCampaignCode } from './getResultsByCampaignCode'
import {
  getCampaignByCode,
  getCampaignById,
  getCampaignCoupons,
} from './campaignUtils'
import type { CampaignResults } from '@/resources/package'
import type { Learner } from '@/resources/learner'
import type { Ticket } from '@/resources/ticket'
import { getScenarioById } from './scenarioUtils'

export async function getCampaignResults(
  customerId: string,
  campaignCode: string,
  campaignId?: string
) {
  const res: CampaignResults = {
    campaignId: '',
    campaignCodeName: '',
    scenarioShouldAssess: false,
    coupons: [],
    rows: [],
  }

  try {
    const campaignData = campaignId
      ? await getCampaignById(campaignId)
      : await getCampaignByCode(campaignCode)

    if (!campaignData || campaignData.customerId !== customerId) {
      return res
    }
    campaignId = campaignData.id

    const results =
      (await getResultsByCampaignCode(campaignData.campaignCode)) || []

    if (campaignData.scenarioDocId) {
      const scenario = await getScenarioById(campaignData.scenarioDocId)
      res.scenarioShouldAssess = scenario?.shouldAssess || false
    }

    res.campaign = campaignData
    res.campaignCodeName = `${campaignData.campaignCode} (${campaignData.name})`

    // Step 1: Get the list of coupons for the campaign from Firestore
    const coupons = await getCampaignCoupons(campaignId)
    const data = await Promise.all(
      coupons.map(async (coupon) => {
        const res: { coupon: Coupon; learner?: Learner; ticket?: Ticket } = {
          coupon,
        }

        if (!res.coupon.learnerId) {
          return res
        }

        const learner = await getLearnerById(res.coupon.learnerId)
        if (learner) {
          res.learner = learner
          const ticket = await getLearnerTicketFromCouponCode(
            res.coupon.learnerId,
            res.coupon.couponCode,
            campaignId as string
          )
          res.ticket = ticket || undefined
        }

        return res
      })
    )

    // Step 3: Iterating each row of the coupon records (from Step 1), find all matching result records (from Step 2) for the coupon
    const rows = await Promise.all(
      data.map(async (item) => {
        const matchingResults = results.filter(
          (result) => result.userInfo.couponCode === item.coupon.couponCode
        )

        if (matchingResults.length === 0) {
          return [
            {
              campaignId,
              couponCode: item.coupon.couponCode,
              displayName: item.learner?.displayName,
              dialogId: '',
              status: item.coupon.isConfirmed
                ? (i18n.t('campaignResult.status.registered') as string)
                : (i18n.t('campaignResult.status.unregistered') as string),
              startedAt: '',
              completedAt: '',
              duration: '',
              overall: '',
              range: '',
              accuracy: '',
              phonology: '',
              fluency: '',
              coherence: '',
              interaction: '',
              coupon: item.coupon,
              ticket: item.ticket,
            },
          ]
        }
        // Step 4-2: If there’s just one, render one row with the status and results
        // Step 4-3: If there are more than one (say 3), render three rows with the status and results
        return matchingResults.map((result) => {
          // const fastResult = result.fastResult || {}
          // const score = fastResult.score || {}
          const fastResult = result.fastResult || {}
          const score = fastResult.score || {}
          const dialogStatus = result.dialogStatus

          const shouldAssess =
            dialogStatus.shouldAssess === true ||
            (dialogStatus.shouldAssess === undefined &&
              dialogStatus.isAssessed === true)

          const getScore = (type: keyof typeof score) =>
            shouldAssess && score[type]
              ? `${score[type].cefrLevel} (${roundToTwoDecimals(
                  score[type].score
                )})`
              : ''

          const startedAt = getJstTime(dialogStatus.dialogStartedAt)
          const completedAt = getJstTime(dialogStatus.dialogEndedAt)

          return {
            campaignId,
            couponCode: result.userInfo.couponCode,
            dialogId: dialogStatus.channelName,
            displayName: item.learner?.displayName,
            status: determineCampaignStatus(
              item.coupon.isConfirmed,
              dialogStatus.isAssessed,
              dialogStatus.isEnded
            ),
            startedAt,
            completedAt,
            duration: calculateDuration(startedAt, completedAt),
            overall: getScore('cefrOverall'),
            range: getScore('range'),
            accuracy: getScore('accuracy'),
            phonology: getScore('phonology'),
            fluency: getScore('fluency'),
            coherence: getScore('coherence'),
            interaction: getScore('interaction'),
            shouldAssess,
            score,
            coupon: item.coupon,
            ticket: item.ticket,
          }
        })
      })
    )

    res.campaignId = campaignId
    res.coupons = data.map((d) => d.coupon)
    res.rows = rows.flat() as CampaignResults['rows']
  } catch (error) {
    console.log(error)
  }

  return res
}
