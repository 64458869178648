import { db } from '@/plugins/firebase'
import type { Scenario } from '@/resources/scenario'
import { doc, getDoc } from 'firebase/firestore'

const loadedScenarios: { [key: string]: Scenario } = {}

export async function getScenarioById(scenarioId: string) {
  if (loadedScenarios[scenarioId]) {
    return loadedScenarios[scenarioId]
  }

  const scenarioRef = doc(db, 'scenarios', scenarioId)
  const snapshot = await getDoc(scenarioRef)

  if (!snapshot.exists()) {
    return null
  }

  loadedScenarios[scenarioId] = snapshot.data() as Scenario

  return loadedScenarios[scenarioId]
}
