import { functions } from '@/plugins/firebase'
import type { Result } from '@/resources/result'
import { httpsCallable } from 'firebase/functions'

const MINUTE = 60 * 1000

/**
 * Get data using cloud function getEndResultsByCampaignCodeForManagers
 * @param campaignCode- The name of the campaign to query results for.
 * @returns {Result[]}
 */
export async function getResultsByCampaignCode(
  campaignCode: string
): Promise<Result[]> {
  const getEndedResultsByCampaignCodeForManagers = httpsCallable(
    functions,
    'getEndedResultsByCampaignCodeForManagers',
    {
      timeout: MINUTE * 10,
    }
  )
  const response = await getEndedResultsByCampaignCodeForManagers({
    campaignCode,
  })

  return response.data as Result[]
}
