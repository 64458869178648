<style scoped>
.sticky-header {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}

.sticky-header-no-border:nth-child(1) {
  border-right: 0;
}
</style>

<template>
  <div class="d-flex flex-column" style="gap: 20px">
    <span class="text-h6">
      {{ $t('packageResults.learnerPopulationByProgress') }}
    </span>

    <template v-if="loading">
      <v-card :loading="loading">
        <div style="padding: 16px">
          {{ $t('packageResults.loading') }}
        </div>
      </v-card>
    </template>

    <v-simple-table v-else dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="sticky-header sticky-header-no-border"></th>
            <template v-for="item in data">
              <th
                v-if="speakingTestOnly ? item.scenarioShouldAssess : true"
                :key="item.campaignId"
                style="width: 300px; min-width: 300px; max-width: 300px"
              >
                {{ item.campaign?.name }}
              </th>
            </template>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th class="sticky-header" style="width: 150px; min-width: 150px">
              {{ $t('packageResults.unregistered') }}
            </th>

            <template v-for="item in data">
              <td
                v-if="speakingTestOnly ? item.scenarioShouldAssess : true"
                :key="item.campaignId + '_unregistered'"
              >
                {{ unregistered(item.rows) }}
              </td>
            </template>
          </tr>
          <tr>
            <th class="sticky-header">
              {{ $t('packageResults.incomplete') }}
            </th>

            <template v-for="item in data">
              <td
                v-if="speakingTestOnly ? item.scenarioShouldAssess : true"
                :key="item.campaignId + '_incomplete'"
              >
                {{ incomplete(item.rows) }}
              </td>
            </template>
          </tr>
          <tr>
            <th class="sticky-header">
              {{ $t('packageResults.complete') }}
            </th>

            <template v-for="item in data">
              <td
                v-if="speakingTestOnly ? item.scenarioShouldAssess : true"
                :key="item.campaignId + '_complete'"
              >
                {{ complete(item.rows) }}
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script lang="ts">
import type { CampaignResults } from '@/resources/package'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LearnerPopulationData',

  props: {
    data: {
      type: Array as () => CampaignResults[],
      required: true,
    },
    speakingTestOnly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    unregistered(rows: CampaignResults['rows']) {
      // coupons.isConfirmed:false
      return rows.filter((r) => !r.coupon?.isConfirmed).length
    },
    incomplete(rows: CampaignResults['rows']) {
      // ticket.isAvailable:true
      return rows.filter((r) => r.ticket?.customerOptions.isAvailable === true)
        .length
    },
    complete(rows: CampaignResults['rows']) {
      // ticket.isAvailable:false
      return rows.filter((r) => r.ticket?.customerOptions.isAvailable === false)
        .length
    },
  },
})
</script>
