<template>
  <td
    v-if="result"
    style="width: 300px"
    :class="cefrStyleClass(result.cefrLevel || '')"
  >
    {{ result.cefrLevel }}
    ({{ result.score }})
  </td>
  <td v-else style="width: 300px">-</td>
</template>

<script lang="ts">
import type { CampaignResults } from '@/resources/package'
import type { Result } from '@/resources/result'
import { defineComponent } from '@vue/composition-api'
import { cefrStyleClass } from '@/components/utils/commonUtils'

type ScoreKey = keyof Result['fastResult']['score']

export default defineComponent({
  name: 'OverviewCouponColumn',

  props: {
    item: {
      type: Object as () => CampaignResults['rows'][0],
      required: true,
    },
    couponCode: {
      type: String,
      required: true,
    },
    tabIndex: {
      type: String,
      required: false,
    },
  },

  computed: {
    result(): Result['fastResult']['score'][ScoreKey] | undefined {
      const key = this.tabIndex as ScoreKey
      return this.item.score?.[key]
    },
  },

  methods: {
    cefrStyleClass,
  },
})
</script>
